<!--
 * @Author: hwu
 * @Date: 2022-06-09 14:25:05
 * @Description: file content
 * @LastEditTime: 2023-08-18 17:21:59
-->
<template>
  <div class="app-container">
    <div class="wh-empty-box">
      <img class="wh-empty-img" src="@/assets/images/empty-1.png" />
      <p class="wh-empty-text">{{ warningMsg }}</p>
    </div>
  </div>
</template>
<script>
import { generateWxProgramLinkApi } from '@/api/wechat'
export default {
  data () {
    return {
      warningMsg: '正在跳转小程序',
      storageLinkKeyPrefix: 'WX_LINK_',
      storageLinkKey: '',
      brandCode: '',
      uniqueId: '',
      programPath: '',
      programQuery: ''
    }
  },
  created () {
    this.uniqueId = this.$route.query.uniqueId ? this.$route.query.uniqueId : ''
    this.brandCode = this.$route.query.brandCode ? this.$route.query.brandCode : ''
    this.programPath = this.$route.query.path ? decodeURIComponent(this.$route.query.path) : ''
    this.programQuery = this.$route.query.query ? this.$route.query.query : ''
    if (!this.uniqueId || !this.programPath) {
      this.warningMsg = '无效的链接'
      return false
    }
    this.storageLinkKey = this.storageLinkKeyPrefix + this.uniqueId
    this.findWxProgramLink()
  },
  methods: {
    findWxProgramLink () {
      const storageLink = localStorage.getItem(this.storageLinkKey)
      if (storageLink) {
        this.linkToProgram(storageLink)
        return
      }
      this.generateProgramLink()
    },
    generateProgramLink () {
      const param = {
        brandCode: this.brandCode,
        isExpire: 0,
        expireType: 1,
        expireInterval: 30,
        path: this.programPath,
        query: this.programQuery
      }
      generateWxProgramLinkApi(param).then((res) => {
        if (res.status !== 0) {
          this.warningMsg = '无法跳转小程序'
          return false
        }
        localStorage.setItem(this.storageLinkKey, res.data)
        this.linkToProgram(res.data)
      })
    },
    linkToProgram (url) {
      location.href = url
    }
  }
}
</script>
